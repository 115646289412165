import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { CommoditiesProvider as Provider } from '../../components/context/commodities-context';

const QUERY = graphql`
  query CommoditiesQuery {
    platform {
      allCommodities {
        id name slug path __typename _entityTypeName
        openGraph {
          title date description { plain }
          image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
        }
      }
    }
  }
`;

export const CommoditiesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allCommodities' ) }>{children}</Provider>;
}