import React from 'react';
import styled, { css } from 'styled-components';
import { keyBy } from 'lodash';

import { Classable, HasChildren, IconComponent, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useTopics, useTrends } from '@shapeable/ui';
import { TrendBubbleDot } from './trend-bubble-dot';
import { useValueChainMap } from '../../hooks/use-value-chain-map';
import { classNames } from '@shapeable/utils';
const cls = classNames('value-chain-map');
import { darken } from 'polished';
import { valueChainMapTrendLines, valueChainMapTrends } from '../../spec';

import { animated, useSpring } from '@react-spring/web'

// -------- Types -------->

export type ValueChainMapProps = Classable & HasChildren & {
  backgroundColor?: string;
}

export const ValueChainMapDefaultProps: ValueChainMapProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type TrendBubbleProps = {
  _color: string;
  _hoverFill: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
    cursor: default;

    ${cls.dot('trend-bubble-icon')} {
      ${theme.FILL('light')};
    }

    ${cls.dot('trend-bubble-small')} {
      cursor: pointer;
    }

    ${cls.dot('rings')} {
      circle {
        filter: drop-shadow(0px 0px 28px rgb(0 0 0 / 0.2));
      }
    }

    ${cls.dot('trend-lines')} {
      opacity: 0.65;
    }

    ${cls.dot('topic-bubble')} {
      cursor: pointer;
    }
    

  `,
});

const TrendBubbleStyles = breakpoints({
  base: css`
    cursor: pointer;

    ${({ _color }: TrendBubbleProps ) => css`
    
      &:hover {
        ${TrendBubbleDot.cls.dot('line')} {
          fill: ${darken(0.1, _color)};
        }
      }

    `}
  `,
});

const TrendBubbleSmallStyles = breakpoints({
  base: css`
    ${({ _hoverFill }: TrendBubbleProps ) => css`
    
      &:hover {
        ${cls.dot('trend-bubble-small-dot')} {
          fill: ${_hoverFill};
        }
      }

    `}
  `,
});



const TopicBubbleStyles = breakpoints({
  base: css`
    ${(props: any) => props.isActive && css`
      stroke: ${theme.FILL('light')};
    `}

    ${(props: any) => css`
      ${cls.dot('topic-bubble-dot')} {
        &:hover {
          fill: ${props._hoverFill};
        }
      }
    `}
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.svg<ContainerProps>`${ContainerStyles}`,
  TrendBubble: styled(animated.svg)<TrendBubbleProps>`${TrendBubbleStyles}`,

  TrendBubbleSmall: styled.g<any>`${TrendBubbleSmallStyles}`,
  TopicBubble: styled.g<any>`${TopicBubbleStyles}`,
};

export const TrendBubbleText: React.FC<Classable & HasChildren> = ({ className, children }) => (
  <text fill="black" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="IBM Plex Sans" fontSize="22" fontWeight="500" letterSpacing="0em" className={cls.sub('trend-bubble-label')}>
  {children}
  </text>
);

export const SmallBubbleText: React.FC<Classable & HasChildren> = ({ className, children }) => (
  <text fill="black" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="IBM Plex Sans" fontSize="15" fontWeight="500" letterSpacing="0em" className={cls.sub('trend-small-bubble-label')}>
  {children}
  </text>
);

 
export const ValueChainMap: Shapeable.FC<ValueChainMapProps> = (props) => {
  const { className, backgroundColor } = props;

  const { activeTrend, setActiveTrend, activeTopic, setActiveTopic } = useValueChainMap();

  
  const trends = useTrends();
  const trendsBySlug = keyBy(trends, 'slug'); 
  const topics = useTopics();
  const topicsBySlug = keyBy(topics, 'slug'); 

  const handleTrendClick = (slug: string) => (event: React.MouseEvent) => {
    event.stopPropagation();
    const trend = trendsBySlug[slug];

    if (trend) {
      setActiveTrend(trend);
    }
  };

  const handleTopicClick = (slug: string) => (event: React.MouseEvent) => {
    event.stopPropagation();
    const topic = topicsBySlug[slug];

    if (topic) {
      setActiveTopic(topic);
    }
  };

  const isTrendBubbleVisible = (slug: string) => {
    return (!activeTrend || activeTrend.slug === slug); 
  };

  const isTrendSmallBubbleVisible = (slug: string) => {
    return (!activeTrend || activeTrend.slug !== slug); 
  };


  return (
    <My.Container overflow="visible" style={{ backgroundColor }} width="802" height="779" viewBox="0 0 802 779" fill="none" className={cls.name(className)}>

    <g className={cls.sub('trend-lines')}>
    {
      valueChainMapTrendLines.map(({ id, d }) => (
        <path key={`trend-line-${id}`} id={id} d={d} stroke="white" strokeDasharray="5 5" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" className="trend-line"></path>
      ))
    }
    </g>

    {
      !activeTrend &&
      <g className={cls.sub('compass')}>
        <path className={cls.sub('shape')} fillRule="evenodd" clipRule="evenodd" d="M413.618 345.655L473 354L413.619 362.345L435.87 391.87L406.345 369.619L398 429L389.655 369.618L360.13 391.87L382.381 362.345L323 354L382.381 345.655L360.13 316.13L389.655 338.381L398 279L406.345 338.381L435.87 316.13L413.618 345.655Z" fill="white"></path>
        <path className={cls.sub('mask')} d="M398 363.68C403.346 363.68 407.68 359.346 407.68 354C407.68 348.654 403.346 344.32 398 344.32C392.654 344.32 388.32 348.654 388.32 354C388.32 359.346 392.654 363.68 398 363.68Z" fill="#F0F0F0"></path>
      </g>
    }

    <g className={cls.sub('rings')}>
      <circle id="ring-inner" cx="397.74" cy="356.74" r="171.74" stroke="white" strokeWidth="2.5" className="ring"></circle>
      <circle id="ring-outer" cx="397.39" cy="354.39" r="305.39" stroke="white" strokeWidth="2.5" className="ring"></circle>
    </g>
    
    {
      valueChainMapTrends.map(({ slug, color, x, y, topicNodes }) => {

        return (
          activeTrend?.slug === slug &&
          <g key={`trend-topics-${slug}`} className={`trend-topics trend-${slug}`}>
          {
            topicNodes.map(({ slug, text, bubble, path }, i) => {
              
              const isActive = activeTopic?.slug === slug;

              const values = activeTopic? {
                cx: bubble.x,
                cy: bubble.y,
                r: 14.85,
                
              } : useSpring({
                from: {
                  r: 5,
                  cx: x,
                  cy: y,
                },
                to: {
                  r: 14.85,
                  cx: bubble.x,
                  cy: bubble.y,
                },
                delay: 20,
                config: { tension: 180, friction: 20 },
              });
              
              // console.log(slug, activeTopic, isActive);
              return (
                <My.TopicBubble _hoverFill={darken(0.2, color)} onClick={handleTopicClick(slug)} key={`topic-bubble-${slug}`} className={cls.sub('topic-bubble')}>
                  <SmallBubbleText>
                  {
                    text.map(({ x, y, text }, index) => (
                      <tspan key={`topic-bubble-text-${slug}-${index}`} x={x} y={y}>{text}</tspan>
                    ))
                  }
                  </SmallBubbleText>
                  <path d={path} stroke={isActive ? '#000000' : '#DDDDDD'} strokeWidth={isActive ? 3 : 2.5} strokeMiterlimit="10" strokeDasharray="5 5" strokeLinecap="round" className="topic-line"></path>
                  <animated.circle strokeWidth={isActive ? 3 : 0} cx={values.cx} cy={values.cy} stroke="#000000" r={values.r} fill={color} className={cls.sub('topic-bubble-dot')}></animated.circle>
                </My.TopicBubble>
              );
            })
          }
          </g>
        )
      })
    }
    
    {
      activeTrend && 
      <g className={cls.sub('trend-bubbles-small')}>
      {
        valueChainMapTrends.map(({ slug, color, smallBubble }) => {
          return (
            isTrendSmallBubbleVisible(slug) && (
              <My.TrendBubbleSmall _hoverFill={darken(0.2, color)} onClick={handleTrendClick(slug)} key={`trend-bubble-small-${slug}`} className={cls.sub('trend-bubble-small')}>
                <SmallBubbleText>
                {
                  smallBubble.text.map(({ x, y, text }, index) => (
                    <tspan key={`trend-bubble-small-text-${slug}-${index}`} x={x} y={y}>{text}</tspan>
                  ))
                }
                </SmallBubbleText>
                <circle cx={smallBubble.x} cy={smallBubble.y} r="14.85" fill={color} className={cls.sub('trend-bubble-small-dot')}></circle>
              </My.TrendBubbleSmall>
            )
          );
        })
      }
      </g>
    }

    <g className={cls.sub("trend-bubbles")}>
    {
      valueChainMapTrends.map(({ width, height, x, y, slug, color, icon: icon, bubble, smallBubble }, i) => {
        const Icon = icon.component;
        const isActive = activeTrend?.slug === slug;

        const centerX = 383.24;
        const centerY = 356.74;

        const values = activeTrend ? {
          x,
          y,
          opacity: 1,
        } : useSpring({
          from: {
            x: centerX,
            y: centerY,
            opacity: 0,
          },
          to: {
            x,
            y,
            opacity: 1,
          },
          delay: i * 80,
          config: { tension: 180, friction: 14 },
        });


        return (
          isTrendBubbleVisible(slug) && (
            <My.TrendBubble style={{ opacity: values.opacity }} key ={`trend-bubble-${slug}`} onClick={handleTrendClick(slug)} _color={color} preserveAspectRatio="none" width={width} height={height} x={values.x} y={values.y} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <TrendBubbleText>
            {
              bubble.text.map(({ x, y, text }, index) => (
                <tspan key={`trend-bubble-text-${slug}-${index}`} x={x} y={y}>{text}</tspan>
              ))
            }
            </TrendBubbleText>
            <TrendBubbleDot isActive={isActive} x={bubble.x} width={101.56} height={101.56} color={color} />
            <Icon x={icon.x?.toFixed(2)} y={icon.y?.toFixed(2)} width={icon.width?.toString()} height={icon.height?.toString()} className={cls.sub('trend-bubble-icon')} />
            </My.TrendBubble>
          )
        );
      })
    }
    </g>

    </My.Container>

  )
};

ValueChainMap.cls = cls;
ValueChainMap.defaultProps = ValueChainMapDefaultProps;