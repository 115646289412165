import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Entity, EntityTypeName, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Grid, GridProps, useFullscreen, EntityThumbnailCard } from '@shapeable/ui';
import { classNames, entityTypeNameFor } from '@shapeable/utils';
const cls = classNames('entity-thumbnail-card-grid');

// -------- Types -------->

export type EntityThumbnailCardGridProps = Omit<GridProps, 'items'> & { 
  items?: Entity[];
  title?: string;
  cardProps?: Partial<Record<EntityTypeName, any>>;
};

export const EntityThumbnailCardGridDefaultProps: EntityThumbnailCardGridProps = {
  spacing: 2,
  maxColumns: 4,
  desktopAutoColumns: 4,
  cardProps: {},
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
  desktop: css`
    padding: 0 ${theme.UNIT(4)} ${theme.UNIT(4)} ${theme.UNIT(4)};
  `
});

const CardStyles = breakpoints({
  base: css`
  
  `,
});

const GridStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Grid: styled(Grid)`${GridStyles}`,
  Card: styled(EntityThumbnailCard)`${CardStyles}`,
};

export const EntityThumbnailCardGrid: Shapeable.FC<EntityThumbnailCardGridProps> = (props) => {
  const { className, children, items, ...rest } = props;

  const fs = useFullscreen()

  return (
    <My.Container className={cls.name(className)}>
      <My.Grid {...rest} desktopAutoColumns={fs.isActive ? 5 : 4} items={items.map(entity => {
        const entityTypeName = entityTypeNameFor(entity) as EntityTypeName;
        const cardProps = props.cardProps[entityTypeName] || {};
        
        return (
          <My.Card key={entity.id} entity={entity} {...cardProps} />
        )
      })} />
    
    {children}
    </My.Container>
  )
};

EntityThumbnailCardGrid.defaultProps = EntityThumbnailCardGridDefaultProps;
EntityThumbnailCardGrid.cls = cls;