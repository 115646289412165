import React from 'react';
import styled, { css } from 'styled-components';
import { ChatAutoMessagePrompt, PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ChatProvider, SliceLayoutBoundary, useEntity, useLang } from '@shapeable/ui';
import { GptLayout } from '@shapeable/gpt';
import { ChevronLeftIconGlyph } from '@shapeable/icons';
import { EntityTitleHeader } from '@shapeable/web';
import { classNames } from '@shapeable/utils';

const cls = classNames('page-layout-gpt');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const GptStyles = breakpoints({
  base: css`
    
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Gpt: styled(GptLayout)`${GptStyles}`,

    ChevronLeft: styled(ChevronLeftIconGlyph)

};

export const PageLayoutGpt: PageLayoutComponent = 
(props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const t = useLang();

  const autoPrompts: ChatAutoMessagePrompt[] = [
    {
      id: 'adsw',
      type: 'text',
      values: { question: "Tell me about CopperConnect" },
    },
    {
      id: 'copper-connect', 
      type: 'text',
      values: { question: t("Tell me more about the CopperConnect Explorer") },
    },
    {
      id: 'purpose',
      type: 'text',
      values: { question: "What is the purpose of CopperConnect?" },
    },
  ];

  return (
    <ChatProvider value={{ 
      autoPrompts,
    }}>
      <My.Container className={cls.name(className)}>
        <EntityTitleHeader backgroundColor='#0AA288' entity={entity} />
        <SliceLayoutBoundary>
          <My.Gpt />
        </SliceLayoutBoundary>
      </My.Container>
    </ChatProvider>
  )
};