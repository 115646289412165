import { 
  createSliceLayout,
  PageKnowledgeHubLayout, 
  PageKnowledgeHubLayoutProps,
  SliceColorStrip,
  SliceColorStripProps,
  SliceContactForm,
  SliceContactFormProps, 
} from '@shapeable/ui';


// Augment standard site layouts here by exporting additional or replacement layouts.
// (simply use the same key to replace any existing slice layouts).

import { CitationCard } from '../entities/citation-card';
import { BRAND_COLORS } from '../../theme';

export const SliceLayoutColorStrip = createSliceLayout<SliceColorStripProps>(
  SliceColorStrip, {
    boundary: 'none',
    props: {
      colors: BRAND_COLORS
    }
  }
);

export const SliceLayoutKnowledgeHub = createSliceLayout<PageKnowledgeHubLayoutProps>(
  PageKnowledgeHubLayout, {
    boundary: 'content-bleed',
    verticalPadding: 'medium',
    props: {
      showAllTab: true,
      entityTypes: { 
        Post: { label: 'Articles', sortBy: 'published', reverse: true }, 
        Citation: { label: 'Research', cardComponent: CitationCard, spacing: 4 },
      }
    }
  }
);

// Add Organisation Types to Contact Form (TODO: move to config)
export const SliceLayoutContactForm = createSliceLayout<SliceContactFormProps>(
  SliceContactForm, {
    props: {
      variant: 'inline',
      showMembershipCheckbox: false,
      organisationTypes: [
        "Capital Providers",
        "Communities",
        "Governments",
        "METS",
        "NGOs",
        "Producers",
        "Researchers",
      ]
    }
  }
);



