import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, IconComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, IconTextValue, useEntity, useLang } from '@shapeable/ui';
import { AiCIcon, AiUpperCaseIcon, AiUpperCaseIconGlyph } from '@shapeable/icons';
import { CopperAiDescription } from '../../data';
import { classNames } from '@shapeable/utils';
const cls = classNames('copper-ai-text-value');

// -------- Types -------->

export type CopperAiTextValueProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const CopperAiTextValueDefaultProps: Omit<CopperAiTextValueProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    gap: ${theme.UNIT(5)};
    cursor: pointer;
  `,
});

const CopperAiPillStyles = breakpoints({
  base: css`
    border-radius: 100px;
    font-size: 0.8em;
    font-weight: 600;
    width: 160px;
    padding: ${theme.UNIT(2)} ${theme.UNIT(6)} ${theme.UNIT(2)} ${theme.UNIT(2)};
    background-color: ${theme.COLOR('#EBEBEB')};
    &:hover {
      border-color: ${theme.COLOR('primary')};
      ${theme.FILL('link-hover')};
      color: ${theme.COLOR('link-hover')};
    }
  `,
  desktop: css`

  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    font-weight: 300;
    font-size: 0.8em;
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    CopperAiPill: styled(IconTextValue)`${CopperAiPillStyles}`,
    Description: styled(ContentNode)`${DescriptionStyles}`,
  
};

export const CopperAiTextValue: Shapeable.FC<CopperAiTextValueProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const t = useLang();
  
  return (
   <My.Container className={cls.name(className)}>
    <My.CopperAiPill href='#view=copperAi' iconSize={50} color='dark' iconComponent={AiUpperCaseIconGlyph}>{t('Use Copper AI')}</My.CopperAiPill>
    <My.Description entity={CopperAiDescription} />
    {children}
   </My.Container>
  )
};

CopperAiTextValue.defaultProps = CopperAiTextValueDefaultProps;
CopperAiTextValue.cls = cls;