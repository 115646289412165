import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Entity, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useView, EntityFilters, EntityCard, EntityCardGrid, SearchBar, useSearch } from '@shapeable/ui';
import { useInnovations } from '../../hooks/use-innovations';
import { EntityThumbnailCardGrid } from './entity-thumbnail-card-grid';
import { linkedMatchesFilter } from '../../utils/linked-matches-filter';
import { compact } from 'lodash';
import { useInnovationsFilters } from '../../hooks/use-innovations-filters';
import { InnovationIcon } from '@shapeable/icons';
import { classNames } from '@shapeable/utils';
import { ExplorerView } from './explorer-grid-layout';
const cls = classNames('explorer-innovations-layout');

// -------- Types -------->

export type ExplorerInnovationsLayoutProps = Classable & HasChildren & {
}

export const ExplorerInnovationsLayoutDefaultProps: ExplorerInnovationsLayoutProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)};
    .shp--card__body {
      background: ${theme.COLOR('light')};
    };
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    box-sizing: border-box;
    padding-bottom: ${theme.UNIT(2)};
  `,
  tablet: css`
    padding-bottom: ${theme.UNIT(3)};
  `,
});

const SearchBarStyles = breakpoints({
  base: css`
    
  `,
});

const GridStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
    SearchBar: styled(SearchBar)`${SearchBarStyles}`,
    Grid: styled(EntityCardGrid)`${GridStyles}`,
};

export const ExplorerInnovationsLayout: Shapeable.FC<ExplorerInnovationsLayoutProps> = (props) => {
  const { className } = props;
  
  const view = 'explorer';
  const { filters } = useView<ExplorerView>(view);

  const { themes = [], technologies = [], catalysts = [], horizonTypes = [] } = filters;

  const { selects } = useInnovationsFilters();
  const allInnovations = useInnovations();

  // TODO - hook this up to a proper query if the dataset becomes large
  const innovations = allInnovations.filter(innovation => (
    linkedMatchesFilter(innovation.trends, themes) &&
    linkedMatchesFilter(innovation.technologies, technologies) &&
    linkedMatchesFilter(innovation.catalysts, catalysts) &&
    linkedMatchesFilter(compact(innovation.horizons?.map(h => h.type)), horizonTypes)
  ));

  const { searchTerm, setSearchTerm, filteredItems } = useSearch(innovations);

  const onChange = (name: string) => { 
    setSearchTerm(name);
   };

  return (
    <My.Container className={cls.name(className)}>
      <My.Filters view={view} selects={selects} results={allInnovations} />
      <My.SearchBar variant='allBorders' searchTerm={searchTerm} onChange={onChange} placeholder={`Search Innovations`}/>
      <My.Grid items={filteredItems} />
    </My.Container>
  )
};

ExplorerInnovationsLayout.cls = cls;
ExplorerInnovationsLayout.defaultProps = ExplorerInnovationsLayoutDefaultProps;