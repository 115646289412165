import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Entity, HasChildren, Shapeable, SubTopic, Trend } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils'
import { OutlineEntityDotLink, useEntity } from '@shapeable/ui';
const cls = classNames('topics-embed');

import { sortBy } from 'lodash';
import { Topic } from '@shapeable/copper-connect-types';

// -------- Types -------->

export type EntityWithSubTopics = Entity & {
  topics?: SubTopic[];
}

export type SubTopicsEmbedProps = Classable & HasChildren & { 
};

export const SubTopicsEmbedDefaultProps: SubTopicsEmbedProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(4)};
    margin-bottom: ${theme.UNIT(8)};
  `,
});

const ItemStyles = breakpoints({
  base: css`
  
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Item: styled(OutlineEntityDotLink)<ContainerProps>`${ItemStyles}`,

};

export const SubTopicsEmbed: Shapeable.FC<SubTopicsEmbedProps> = (props) => {
  const { className, children } = props;
  
  // embeds the subTopics for the current topic

  const entity = useEntity() as Topic;

  return (
    <My.Container className={cls.name(className)}>
    {
      sortBy(entity.subTopics, 'outlineNumber').map(subTopic => <My.Item entity={subTopic} key={entity.id} />)
    }
    </My.Container>
  )
};

SubTopicsEmbed.defaultProps = SubTopicsEmbedDefaultProps;
SubTopicsEmbed.cls = cls;