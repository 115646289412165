import { AirIcon } from "../components/icons/air-icon";
import { COLOR_VALUE_AIR } from "../theme";
import { TrendNodeSpec } from "./types";

export const trendAir: TrendNodeSpec = {
  width: 158,
  height: 143,
  x: 15,
  y: 304,
  slug: 'air',
  color: COLOR_VALUE_AIR,
  icon: {
    x: 56, y: 29, width: 45, height: 45,
    component: AirIcon,
  },
  bubble: {
    text: [
      { x: 63.8202, y: 136.829, text: 'Air' },
    ],
    x: 28,
    y: 0,
  },
  smallBubble: {
    x: 94.33, y: 354.329,
    text: [
      { x: 48.666, y: 359.25, text: 'Air' },
    ],
  },





  topicNodes: [
    
    {
      slug: 'thermal',
      text: [ 
        { x: 464.827, y: 543.125, text: 'Thermal' },
      ],
      bubble: { x: 484.318, y: 501.609 },
      path: "M96.0234 356.177C96.0234 356.177 343.263 351.047 483.523 499.967",
    },

    {
      slug: 'air-quality-control',
      text: [ 
        { x: 498.911, y: 190.904, text: 'Air Quality' },
        { x: 520.576, y: 205.904, text: 'Control' },
      ],
      bubble: { x: 484.317, y: 205.391 },
      path: "M96.0234 349.338C96.0234 349.338 343.263 354.468 483.523 205.548",
    },

    {
      slug: 'design',
      text: [ 
        { x: 597.171, y: 291.684, text: 'Design' },
      ],
      bubble: { x: 559.566, y: 294.68 },
      path: "M96.0215 349.337C96.0215 349.337 327.171 364.667 559.421 294.647",
    },

    {
      slug: 'emission-monitoring-and-process-optimisation',
      text: [ 
        { x: 585.673, y: 432.21, text: 'Emission' },
        { x: 544.569, y: 447.21, text: 'Monitoring and' },
        { x: 501.737, y: 462.21, text: 'Process Optimisation' },
      ],
      bubble: { x: 559.569, y: 411.46 },
      path: "M96.0225 357.178C96.0225 357.178 327.172 337.848 559.422 410.868",
    }
  ],
};
