import { Entity } from "@shapeable/types";
import { entityTypeNameFor } from "@shapeable/utils";

import { get } from 'lodash';

export const isExplorerEntity = (entity: Entity) => {
  const entityTypeName = entityTypeNameFor(entity);
  const isExplorerGrid = entity.name === 'Explorer';
  if (['Trend', 'Topic', 'Commodity', 'SubTopic'].includes(entityTypeName) || isExplorerGrid) {
    return true;
  }

  return entityTypeName === 'Page' && ['PageLayoutThemeSupportingInfo'].includes(get(entity, 'page.layout.component'));
};
