import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { PageContextDefinition, useEmbedComponents, EmbedComponentsProvider, LinkPathTransformerProvider, useEntity, useAuth, useSiteMenuBar, ViewProvider, UiProvider, useOrganisations, useReady } from '@shapeable/ui';
import * as pageLayouts from './page-layouts';
import * as sliceLayouts from './slice-layouts';
import { LANG_STRINGS } from '../lang';
import { themeOverrides, LayoutStyles, COLOR_VALUE_MINE_DEVELOPMENT, CORE_7, COLOR_VALUE_MINERAL_PROCESSING, CORE_3, ICAA } from '../theme';
import { CitationsProvider } from '../gatsby/providers/citations-provider';
import { TrendsExplorerProvider } from '../gatsby/providers/trends-explorer-provider';
import { InnovationsProvider } from '../gatsby/providers/innovations-provider';
import { CommodityGroupsProvider } from '../gatsby/providers/commodity-groups-provider';
import { OrganisationsProvider } from '../gatsby/providers/organisations-provider';
import { PeopleProvider } from '../gatsby/providers/people-provider';
import { TopicsProvider } from '../gatsby/providers/topics-provider';
import { TrendsProvider } from '../gatsby/providers/trends-provider';
import { CatalystsProvider } from '../gatsby/providers/catalysts-provider';
import { HorizonTypesProvider } from '../gatsby/providers/horizon-types-provider';
import { ImageEmbed } from './elements/image-embed';
import { TopicsEmbed } from './entities/topics-embed';
import { SubTopicsEmbed } from './entities/sub-topics-embed';
import { useValueChainMap } from '../hooks/use-value-chain-map';
import { menuItemIsAvailable } from '../utils/menu-item-is-available';
import { isExplorerEntity } from '../utils/is-explorer-entity';
import { SidebarButtons } from './elements/sidebar-buttons';
import { CommoditiesProvider } from '../gatsby/providers/commodities-provider';
import { CatalystIcon } from './elements/catalyst-icon';
import { FileAssetCard } from './entities/file-asset-card';
import { ExplorerCitationCard } from './entities/explorer-citation-card';
import { EconomiesProvider } from '../gatsby/providers/economies-provider';
import { CatalystCard } from './entities/catalyst-card';
import { TechnologiesProvider } from '../gatsby/providers/technologies-provider';
import { TechnologyIcon } from './elements/technology-icon';
import { PageCard } from './entities/page-card';
import { PostsProvider } from '../gatsby/providers/posts-provider';
import { LayoutShell, SiteFooterLayout, WebConfig, WebProvider } from '@shapeable/web';

import { CommodityIcon } from '@shapeable/icons';
import { EntityMapPanel, MapConfig, MapProvider, MapState } from '@shapeable/maps';
import { DEFAULT_MAP_ENTITY_TYPE_NAMES } from '../data';
import { OrganisationIcon } from '@shapeable/icons';
import { GptConfig, GptProvider } from '@shapeable/gpt';
import { OrganisationTooltip } from './entities/organisation-tooltip';

const initialMapState: MapState = {
  isVisible: false,
  isInitialized: false,
  zoom: 2,
  entityTypeNames: DEFAULT_MAP_ENTITY_TYPE_NAMES,
  latitude: -5.441022303718128,
  longitude: 117.59765625000352,
};

export type LayoutPropTypes = Classable & HasChildren & {
  path?: string;
  data?: any;
  pageContext?: PageContextDefinition;
};

const LayoutDefaultProps: LayoutPropTypes = {
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${LayoutStyles};
  `,
});

const FooterStyles = breakpoints({
  base: css`
  `
});

const BodyStyles = breakpoints({
  base: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  `,
});



// -------- Components -------->

const My = {
  Container: styled(LayoutShell)`${ContainerStyles}`,
  Body: styled.div`${BodyStyles}`,
    Footer: styled(SiteFooterLayout)`${FooterStyles}`,
  
  Map: () => {
    const organisations = useOrganisations();
    return <EntityMapPanel items={organisations} />; 
  },
};

export const Layout: React.FC<LayoutPropTypes> = (props) => {
  const { className, children } = props;

  const embedComponents = useEmbedComponents();

  const entity = useEntity();

  const menuBar = useSiteMenuBar();
  const auth = useAuth();

  const valueChainMap = useValueChainMap();

  const linkPathTransformer = (path: string) => {
    if (path.match(/\/wikiore\-/)) {
      return path.replace(/^\/wikiore\-/, '/appendices/wikiore-');
    }
    return path;
  };

  const { isReady } = useReady();

  const mapConfig: MapConfig = {
    initialMapState,
    entityTypeMapMarkers: {
      Economy: OrganisationIcon,
    },
    availableEntityTypeNames: ['Organisation'],
  };

  const gptConfig: GptConfig = {
    title: 'Copper AI',
  }

  const config: WebConfig = {
    layouts: { pageLayouts, sliceLayouts, defaultPageLayout: pageLayouts.PageLayoutDefault },
    langStrings: LANG_STRINGS,
    theme: themeOverrides, 
    sidebar: {
      isAvailable: () => auth.isAuthenticated && isExplorerEntity(entity),
      showFullscreenButton: false,
      color: ICAA, 
      onClick: () => { valueChainMap.toggle() },
      buttons: <SidebarButtons />
    },
    menuBar: {
      delay: 0,
      backgroundColor: '#43846E',
      color: 'light',
      buttonColor: 'light',
      isInverted: true,
      slideDownDuration: 0,
      showShadow: false,
    },
    cookieBannerFeaturesList: 
      <ul>
        <li><b>Contact Form Cookie:</b> enables the form feature (Netlify).</li>
      </ul>
    ,
    header: {
      menuItemIsAvailable: menuItemIsAvailable(auth),
      hoverColor: '#88B68D',
      activeColor: '#88B68D',
    },
    embeds: {
      // ...embedComponents,
      // img: ImageEmbed,
      // Topics: TopicsEmbed,
      // SubTopics: SubTopicsEmbed,
    },
    entity: {
      labels: {
        Post: 'Case Study',
      },
      icons: {
        Catalyst: CatalystIcon,
        Technology: TechnologyIcon,
        Commodity: CommodityIcon,
        CommodityGroup: CommodityIcon,
      },
      tooltips: {
        Organisation: OrganisationTooltip,
      },
      cards: {
        FileAsset: FileAssetCard,
        Citation: ExplorerCitationCard,
        Catalyst: CatalystCard,
      },
      dataProviders: [
        TrendsExplorerProvider, InnovationsProvider,
        TechnologiesProvider, CommodityGroupsProvider,
        OrganisationsProvider, CommoditiesProvider,
        PeopleProvider, TopicsProvider, TrendsProvider,
        CatalystsProvider, HorizonTypesProvider, CitationsProvider,
        EconomiesProvider, PostsProvider,
      ],
    }
  };

  return (
    <WebProvider value={config}>
    <ViewProvider>
    <LinkPathTransformerProvider value={linkPathTransformer}>
    
      { /* @ts-ignore */ }
      
    <EmbedComponentsProvider value={{
      ...embedComponents,
      img: ImageEmbed,
      Topics: TopicsEmbed,
      SubTopics: SubTopicsEmbed,
    }}>
      <GptProvider value={{
        ...gptConfig,
      }}>
      <MapProvider value={mapConfig}>
      <My.Container
        className={className}
        includeTooltip
        hideOverflow={false}
        tooltipProps={{
          backgroundColor: '#FFFFFF',
        }}
      >
        { 
          isReady && <My.Map /> 
        }
        <My.Body>
        {children}
        </My.Body>
      </My.Container>
      </MapProvider>
      </GptProvider>
    </EmbedComponentsProvider>
    </LinkPathTransformerProvider>
    </ViewProvider>
    </WebProvider>
  );
  
  
};

Layout.defaultProps = LayoutDefaultProps;

