import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Entity, EntityTypeName, Shapeable, Topic } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Grid, GridProps, useFullscreen } from '@shapeable/ui';
import { ValueChainCard } from './value-chain-card';
import { classNames, coalesce, entityTypeNameFor } from '@shapeable/utils';

const cls = classNames('value-chain-card-grid');

// -------- Types -------->

export type ValueChainCardGridProps = Omit<GridProps, 'items'> & { 
  items?: Entity[];
  title?: string;
  cardProps?: Partial<Record<EntityTypeName, any>>;
};

export const ValueChainCardGridDefaultProps: ValueChainCardGridProps = {
  spacing: 2,
  maxColumns: 4,
  desktopAutoColumns: 4,
  cardProps: {},
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const CardStyles = breakpoints({
  base: css`
  
  `,
});

const GridStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Grid: styled(Grid)`${GridStyles}`,
  Card: styled(ValueChainCard)`${CardStyles}`,
};

export const ValueChainCardGrid: Shapeable.FC<ValueChainCardGridProps> = (props) => {
  const { className, children, items, ...rest } = props;

  const fs = useFullscreen()
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Grid {...rest} desktopAutoColumns={fs.isActive ? 5 : 4} items={items.map(entity => {
        const entityTypeName = entityTypeNameFor(entity) as EntityTypeName;
        const cardProps = props.cardProps[entityTypeName] || {};

        let color = coalesce(entity, ['color']);

        if (entityTypeName === 'Topic' && color) {
          color = {
            ...color,
            value: color.value + 'B3',
          };
        }

        const modifiedEntity = {
          ...entity,
          color 
        };

        return (
          <My.Card key={entity.id} entity={modifiedEntity} {...cardProps} />
        )
      })} />
    
    {children}
    </My.Container>
  )
};

ValueChainCardGrid.defaultProps = ValueChainCardGridDefaultProps;
ValueChainCardGrid.cls = cls;