import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, TContentNode, Page, ContentEntity, Slice } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, ContentEntityIntro, ContentEntityRichTitle, ContentNode, ContentTitle, EntityBanner, EntityCardGrid, EntityGrid, LinkButton, SliceLayout, useEntity, useFeedEntries, useLang, usePosts } from '@shapeable/ui';
import { CopperAiTextValue } from '../elements/copper-ai-text-value';
import { PageCard } from './page-card';
import { reverse, sortBy } from 'lodash';
import { FeaturedEntityCard } from './featured-entity-card';
import { classNames } from '@shapeable/utils';
import { EntityMainHeader } from '@shapeable/ui';
const cls = classNames('explorer-main-layout');

// -------- Types -------->

export type ExplorerMainLayoutProps = Classable & HasChildren & { 
  entity?: Page;
};

export const ExplorerMainLayoutDefaultProps: Omit<ExplorerMainLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(10)};
  
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const BannerStyles = breakpoints({
  base: css`
    .shp--image-entity-background,
    .shp--image-entity-background-slideshow {
      overflow: visible;
    }
  `,
});

const ContentStyles = breakpoints({
  base: css`

  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(3)};
  `,
});

const TitleAndIntroStyles = breakpoints({
  base: css`
    
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: 1.10em;
    line-height: 1;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-weight: 300;
    margin-bottom: ${theme.UNIT(4)};
    line-height: 1.10;
    font-size: 1.8em;
  `,
});

const ContentLabelStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('text')};
    font-weight: 300;
  `,
});

const CopperAiTextValueStyles = breakpoints({
  base: css`
    
  `,
});

const PageCardsStyles = breakpoints({
  base: css`
  `,
});

const FeaturedNewsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(4)};
  `,
});

const FeaturedNewsCardStyles = breakpoints({
  base: css`
    
  `,
});

const DiscoverButtonStyles = breakpoints({
  base: css`
    max-width: 250px;
  `,
});

const BannerPreTitleStyles = breakpoints({
  base: css`
    color: #FFF;
    padding-bottom: ${theme.UNIT(1)};
    margin-bottom: ${theme.UNIT(1)};
  `,
});

const BannerTitleStyles = breakpoints({
  base: css`
    color: #FFF;
    font-size: 2em;
    line-height: 1.10;
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled.div`${HeaderStyles}`,
    Banner: styled(EntityBanner)`${BannerStyles}`,
        BannerPreTitle: styled(CellLabel)`${BannerPreTitleStyles}`,
        BannerTitle: styled(ContentEntityRichTitle)`${BannerTitleStyles}`,
      PageCards: styled(EntityGrid)`${PageCardsStyles}`,

    TitleAndIntro: styled.div`${TitleAndIntroStyles}`,
      Title: styled(ContentEntityRichTitle)`${TitleStyles}`,
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,

    Content: styled.div`${ContentStyles}`,
      Section: styled.section`${SectionStyles}`,
        ContentLabel: styled(CellLabel)`${ContentLabelStyles}`,
        CopperAiButtonText: styled(CopperAiTextValue)`${CopperAiTextValueStyles}`,
        FeaturedNews: styled.div`${FeaturedNewsStyles}`,
        FeaturedNewsCard: styled(FeaturedEntityCard)`${FeaturedNewsCardStyles}`,

      DiscoverButton: styled(LinkButton)`${DiscoverButtonStyles}`,
};

export const ExplorerMainLayout: Shapeable.FC<ExplorerMainLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { featurePages, description } = entity;

  const t = useLang();

  const feedEntries = useFeedEntries();
  const posts = usePosts();
  const allItems = sortBy([...feedEntries, ...posts], 'published') as ContentEntity[];
  // const allItems = reverse(sortBy([...feedEntries, ...posts], 'published')) as ContentEntity[];

  const titleRich: ContentEntity = {
    titleRich: {
      text: 'Harnessing the <br> Collaborative Intelligence <br> of the Copper Industry'
    }
  };

  const backgroundImages = entity.banners || (entity.banner && [entity.banner]) || [];

  const pageSlice: Slice = {
    ...entity,
    backgroundImages,
  };

  return (
   <My.Container className={cls.name(className)}>
    <My.Header>
      <My.Banner
        showSlideControls 
        variant='image'
        >
          {/* <My.BannerPreTitle>Case Study</My.BannerPreTitle> */}
          <My.BannerTitle entity={titleRich} />
      </My.Banner>
        <My.PageCards items={featurePages} spacing={1} card={PageCard} columns={4}/>
    </My.Header>

    <My.TitleAndIntro>
      <My.Title entity={entity} />
      <My.Intro entity={entity} />
    </My.TitleAndIntro>

    <My.Content>
      <My.Section>
        <My.ContentLabel>{t('Generate personalised results with:')}</My.ContentLabel>
        <My.CopperAiButtonText entity={entity} />
      </My.Section>

      <My.Section>
          <My.ContentLabel>{t('Latest News')}</My.ContentLabel>
          <My.FeaturedNews>
          {
            allItems.slice(3, 7).map(item => <My.FeaturedNewsCard entity={item} />)
          }
          </My.FeaturedNews>
      </My.Section>
      <My.DiscoverButton href='/news' shape='rounded' >{t('Discover All News')}</My.DiscoverButton>

    </My.Content>

   {children}
   </My.Container>
  )
};

ExplorerMainLayout.defaultProps = ExplorerMainLayoutDefaultProps;
ExplorerMainLayout.cls = cls;