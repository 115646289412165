import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity, useLang, useView } from '@shapeable/ui';
import { AiCIcon, ConnectionsNavigatorIcon, ConnectionsNavigatorIconGlyph, GridIconGlyph, IconComponent } from '@shapeable/icons';
import { classNames } from '@shapeable/utils';
import { TabVariant } from '../elements/tab';
import { TabSpec, Tabs } from '../elements/tabs';
// import { ExplorerView } from '../../hooks/use-explorer';
const cls = classNames('entity-explorer-menu');

// -------- Types -------->

export type ExplorerTabSpec = {
  id: string; 
  label?: string;
  icon?: IconComponent;
};

export type ExplorerTabsProps = Classable & HasChildren & { 
  tabs: TabSpec[]; 
  entity?: Entity;
  activeTab?: string;
  variant?: TabVariant
};

export const ExplorerTabsDefaultProps: Omit<ExplorerTabsProps, 'entity'> = {
  tabs: [],
  variant: 'grid',
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
  desktop: css`
  `,
});

const TabsStyles = breakpoints({
  base: css`
    padding: 0;
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Tabs: styled(Tabs)`${TabsStyles}`,
};

export const ExplorerTabs: Shapeable.FC<ExplorerTabsProps> = (props) => {
  const { className, tabs, variant } = props;
  const { setActiveView, activeView } = useView('explorer', 'explorer');
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Tabs onTabClick={setActiveView} activeTab={activeView} variant={variant} tabs={tabs} />
    </My.Container>
  )
};

ExplorerTabs.defaultProps = ExplorerTabsDefaultProps;
ExplorerTabs.cls = cls;