import { MineralProcessingIcon } from "../components/icons/mineral-processing-icon";
import { COLOR_VALUE_MINERAL_PROCESSING } from "../theme";
import { TrendNodeSpec } from "./types";

export const trendMineralProcessing: TrendNodeSpec = {
  width: 159,
  height: 170,
  x: 534.5,
  y: 519,
  slug: 'mineral-processing',
  color: COLOR_VALUE_MINERAL_PROCESSING,
  icon: {
    x: 58.5, y: 29, width: 41, height: 43,
    component: MineralProcessingIcon,
  },
  bubble: {
    text: [
      { x: 41.8379, y: 134.75, text: 'Mineral' },
      { x: 24.6826, y: 163.75, text: 'Processing' },
    ],
    x: 28,
  },
  smallBubble: {
    x: 612.661, y: 569.028,
    text: [
      { x: 631, y: 594.25, text: 'Mineral' },
      { x: 631, y: 612.25, text: 'Processing' },
    ],
  },



  topicNodes: [

    {
      slug: 'resource-optimisation-and-recovery',
      text: [ 
        { x: 406.497, y: 127.125, text: 'Resource ' },
        { x: 406.497, y: 142.125, text: 'Optimisation and ' },
        { x: 406.497, y: 157.125, text: 'Recovery' },
      ],
      bubble: { x: 445.561, y: 187.759 },
      path: "M617.289 565.161C617.289 565.161 438.836 393.963 444.96 189.482",
    },

    
    {
      slug: 'comminution',
      text: [ 
        { x: 121.844, y: 377.943, text: 'Comminution' },
      ],
      bubble: { x: 236.102, y: 397.218 },
      path: "M612.453 569.996C612.453 569.996 441.255 391.544 236.774 397.667",
    },


    {
      slug: 'concentration',
      text: [ 
        { x: 136.907, y: 255.244, text: 'Concentration' },
      ],
      bubble: { x: 246.029, y: 280.871 },
      path: "M612.453 569.999C612.453 569.999 459.846 395.711 246.108 280.997",
    },

    
    {
      slug: 'extraction',
      text: [ 
        { x: 261.585, y: 167.605, text: 'Extraction' },
      ],
      bubble: { x: 328.604, y: 198.293 },
      path: "M617.996 564.453C617.996 564.453 440.88 414.674 328.288 198.815",
    }
  ],
};
