import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Entity, HasChildren, Shapeable } from '@shapeable/types';
import { Topic } from '@shapeable/copper-connect-types';
import { breakpoints, theme } from '@shapeable/theme';
import { SiteHeaderLayout, SiteHeaderProvider,  } from '@shapeable/web';
import { TopicAsideLayout } from './topic-aside-layout';
import { TopicMainLayout } from './topic-main-layout';
import { ExplorerProvider } from '../providers/explorer-provider';
import { useValueChainMap } from '../../hooks/use-value-chain-map';
import { classNames } from '@shapeable/utils';
import { useEntity } from '@shapeable/ui';
import { ExplorerLayout } from './explorer-layout';
import { GptAsideLayout } from './gpt-aside-layout';
import { GptMainLayout } from './gpt-main-layout';
const cls = classNames('gpt-layout');

// -------- Types -------->

export type GptLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const GptLayoutDefaultProps: Omit<GptLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    background: ${theme.COLOR('light')};
  `,
});

const SiteHeaderStyles = breakpoints({
  base: css`
    
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,

});
const AsideStyles = breakpoints({
  base: css`
    
  `,
});

const MainStyles = breakpoints({
  base: css`
    
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${SiteHeaderStyles}`,
    Layout: styled(ExplorerLayout).attrs(cls.attr('Layout'))`${LayoutStyles}`,
      Aside: styled(GptAsideLayout)`${AsideStyles}`,
      Main: styled(GptMainLayout)`${MainStyles}`
};

export const GptLayout: Shapeable.FC<GptLayoutProps> = (props) => {
  const { className, children, entity } = props;
  const { isShown } = useValueChainMap();

  return (
    <ExplorerProvider>
    <My.Container className={cls.name(className)}>
      <My.Layout
        entity={entity}
        asideHeaderProps={{ showIcon: false }}
        asideIsRevealed={isShown}
        aside={
          <My.Aside />
        }
        main={
          <My.Main entity={entity} />
        }
      />
      {children}
    </My.Container>
    </ExplorerProvider>
  )
};

GptLayout.defaultProps = GptLayoutDefaultProps;
GptLayout.cls = cls;