import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { PageLayoutStandard, SiteFooterLayout } from '@shapeable/web';
const cls = classNames('page-layout-news');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const LayoutStyles = breakpoints({
  base: css`
    /* override title styles via BEM here to affect spacing */ 
    .shp--page-layout-standard__title {
      font-size: 1.5em;
      line-height: 1.2em;
      max-width: 500px;
      margin-bottom: ${theme.UNIT(4)};
      font-family: ${theme.FONT('sans')};
    }

    .shp--page-layout-standard__subtitle {
      font-size: 2em;
      margin-bottom: ${theme.UNIT(8)};
      line-height: 1em;
      font-weight: 300;
      max-width: 500px;
      strong, b {
        font-weight: 500;
      }
    }
  `,
  tablet: css`
    .shp--page-layout-standard__subtitle {
      font-size: 2.75em;
      margin-bottom: ${theme.UNIT(16)};
    }
  `,
  desktop: css`
    .shp--page-layout-standard__subtitle {
      font-size: 3.5em;
      margin-bottom: ${theme.UNIT(30)};
    }
  `
});

const FooterStyles = breakpoints({
  base: css`
  `
});

// -------- Components -------->

const My = {
  Layout: styled(PageLayoutStandard)`${LayoutStyles}`,
    Footer: styled(SiteFooterLayout)`${FooterStyles}`,
};

export const PageLayoutNews: PageLayoutComponent = 
(props) => {
  const { className, children, entity, slices } = props;

  return (
    <>
    <My.Layout 
      entity={entity}
      slices={slices}
      headerProps={{ variant: 'overlay' }}
      useIntro
      className={className}
    />
    <My.Footer backgroundColor='#FFFFFF' />
    </>
  )
};