import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, OutlineEntity, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useView, EntityFilters, Grid, EntityCard, EntityCardGrid, SearchBar, useSearch } from '@shapeable/ui';
import { EntityThumbnailCardGrid } from './entity-thumbnail-card-grid';
import { useTrendsExplorer } from '../../hooks/use-trends-explorer';
import { useMemo } from 'react';
import { sortBy, each, includes } from 'lodash';

import { ValueChainCardGrid } from './value-chain-card-grid';
import { classNames } from '@shapeable/utils';
import { ExplorerView } from './explorer-grid-layout';

const cls = classNames('explorer-theme-layout');

// -------- Types -------->

export type ExplorerThemesLayoutProps = Classable & HasChildren & {
}

export const ExplorerThemesLayoutDefaultProps:  ExplorerThemesLayoutProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)};
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    box-sizing: border-box;
    padding-bottom: ${theme.UNIT(2)};
  `,
  tablet: css`
    padding-bottom: ${theme.UNIT(3)};
  `,
});

const SearchBarStyles = breakpoints({
  base: css`
    
  `,
});

const GridStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
    SearchBar: styled(SearchBar)`${SearchBarStyles}`,
      Grid: styled(ValueChainCardGrid)`${GridStyles}`,
};

export const ExplorerThemesLayout: Shapeable.FC< ExplorerThemesLayoutProps> = (props) => {
  const { className } = props;

  const view = 'explorer';
  const { types, filters } = useView<ExplorerView>(view);
  const { themes = [] } = filters;

  const allTrends = useTrendsExplorer();
  const items = useMemo(() => sortBy(allTrends, 'outlineNumber').reduce<OutlineEntity[]>((acc, trend) => {
    
    if (!themes.length || includes(themes, trend?.slug)) {
      if (includes(types, 'Trend')) {
        acc.push(trend);
      };
      
      if (!includes(types, 'Topic')) return acc;

      each(sortBy((trend?.topics || []), 'outlineNumber'), topic => {
        acc.push(topic);
      });
    }

    return acc;
  }, []), [allTrends, themes, types]);

  const { searchTerm, setSearchTerm, filteredItems } = useSearch(items);

  const onChange = (name: string) => { 
    setSearchTerm(name);
   };

  return (
    <My.Container className={cls.name(className)}>

      <My.Filters
        view={view}
        selects={[
          {
            name: 'themes',
            fieldName: 'trends',
            items: allTrends,
            pluralLabel: 'Value Chain Themes',
          },
        ]}
        typeToggles={[
          { name: 'Trend', label: 'Value Chain Themes' },
          { name: 'Topic', label: 'Topics' },
        ]}
      />
      <My.SearchBar variant='allBorders' searchTerm={searchTerm} onChange={onChange} placeholder={`Search Themes`}/>
      <My.Grid items={filteredItems} spacing={2} />
    </My.Container>
  )
};

 ExplorerThemesLayout.cls = cls;
 ExplorerThemesLayout.defaultProps =  ExplorerThemesLayoutDefaultProps;