import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, Page, ChatAutoMessagePrompt } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import SOURCE_TYPE_FILE_IMAGE from '../../images/file-data-image.png';
import { GptLayout } from '@shapeable/gpt';
import { ChatProvider, ContentEntityIntro, IconTextValue, useEntity, useLang } from '@shapeable/ui';
import { AiCIcon, AiCIconGlyph, AiUpperCaseIcon, AiUpperCaseIconGlyph } from '@shapeable/icons';
import { classNames } from '@shapeable/utils';
import { GptBannerIntro } from '../../data';
const cls = classNames('gpt-main-layout');

// -------- Types -------->

export type GptMainLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const GptMainLayoutDefaultProps: Omit<GptMainLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {
 
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 1em;
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(2)};
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5)} ${theme.UNIT(4)};
    border-top: 3px solid ${theme.COLOR('line-subtle')};
    border-radius: 0px 0px 8px 8px;
    background: var(--shapeable-aside-bg, #EBEBEB);
  `,
  desktop: css`
    margin: 0 ${theme.UNIT(5)} 0;
  `,
});

const IconHeaderStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(32)};
    padding-bottom: ${theme.UNIT(2)};
  `,
});

const BodyStyles = breakpoints({
  base: css`
    padding: 0 ${theme.UNIT(5)} 0;
  `,
});

const GptLayoutStyles = breakpoints({
  base: css`

    .shp--gpt-prompt {
      border-top: none;
      background: none;
    }
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: ${14/13}em;
    color: ${theme.COLOR('text')};
    line-height: 1.5em;
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled.div`${HeaderStyles}`,
    IconHeader: styled(IconTextValue)`${IconHeaderStyles}`,
    Intro: styled(ContentEntityIntro)`${IntroStyles}`,
    Body: styled.div`${BodyStyles}`,
    GptLayout: styled(GptLayout)`${GptLayoutStyles}`,
};

export const GptMainLayout: Shapeable.FC<GptMainLayoutProps> = (props) => {
  const { className, children, entity } = props;
  const t = useLang()
  
  const autoPrompts: ChatAutoMessagePrompt[] = [
    {
      id: 'copper', 
      type: 'text',
      values: { question: t("Tell me more about CopperConnect") },
    },
    {
      id: 'purpose',
      type: 'text',
      values: { question: "What is the purpose of CopperConnect?" },
    },
  ];

  return (

    <ChatProvider value={{ 
      autoPrompts,
      // sourceTypeImages: {
      //   'pdf': SOURCE_TYPE_FILE_IMAGE,
      //  } 
    }}>
    

   <My.Container className={cls.name(className)}>
    <My.Header>
      <My.IconHeader color='dark' iconSize={55} reverse={true} iconComponent={AiUpperCaseIconGlyph}>{t('Copper')}</My.IconHeader>
      <My.Intro entity={GptBannerIntro} />
    </My.Header>
    <My.Body>
      <My.GptLayout />
    </My.Body>
    {children}
   </My.Container>
  </ChatProvider>
  )
};

GptMainLayout.defaultProps = GptMainLayoutDefaultProps;
GptMainLayout.cls = cls;