import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, EntityTypeName, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, SidebarButton, useAuth, useEntity, useLang, useView } from '@shapeable/ui';
import { includes, without } from 'lodash';
import { useValueChainMap } from '../../hooks/use-value-chain-map';
import { classNames } from '@shapeable/utils';
import { EntityMapToggles, useMapUtils } from '@shapeable/maps';
import { ConnectionsNavigatorIconGlyph, WorldMapIcon } from '@shapeable/icons';
import { isExplorerEntity } from '../../utils/is-explorer-entity';
import { WorldMapIconGlyph } from '@shapeable/icons';
import { ExplorerView } from '../entities/explorer-grid-layout';
const cls = classNames('sidebar-buttons');

// -------- Types -------->

export type SidebarButtonsProps = Classable & HasChildren & {
}

export const SidebarButtonsDefaultProps: SidebarButtonsProps = {
};

// -------- Styles -------->

const IconStyles = breakpoints({
  base: css`
    width: 32px;
    height: 32px;
    margin-bottom: ${theme.UNIT(2)};
  `,

});

const TogglesStyles = breakpoints({
  base: css`
    
  `,
});

const ExplorerMapStyles = breakpoints({
  base: css`
    display: flex;
  `,
  desktop: css`
    display: none;
  
  `,
});

const WorldMapStyles = breakpoints({
  base: css`
    
  `,
});


const WorldMapIconStyles = breakpoints({
  base: css`
    width: 36px;
    height: 22px;
    display: none;
    margin-bottom: ${theme.UNIT(3)};
  `,
  tablet: css`
    display: flex;
  `
});




// -------- Components -------->

const My = {
  Container: React.Fragment,
    Toggles: styled(EntityMapToggles)`${TogglesStyles}`,
    ExplorerMap: styled(SidebarButton)`${ExplorerMapStyles}`,
    Icon: styled(ConnectionsNavigatorIconGlyph)`${IconStyles}`,
    WorldMap: styled(SidebarButton)`${WorldMapStyles}`,
    WorldMapIcon: styled(WorldMapIconGlyph)`${WorldMapIconStyles}`,
};

export const SidebarButtons: Shapeable.FC<SidebarButtonsProps> = (props) => {
  const { className } = props;
  const t = useLang();
  const valueChainMap = useValueChainMap();
  const map = useMapUtils();
  const auth = useAuth();
  const entity = useEntity();

  const { activeView, setActiveView } = useView<ExplorerView>('explorer');

  const showValueChainMap = (activeView === 'copperAi' || activeView === 'explorer') || isExplorerEntity(entity);

  return (
    <My.Container>
      {/* {
        map.isVisible && 
        <My.Toggles />
      } */}
      {
        !map.isVisible && 
        <My.WorldMap onClick={map.showOnClick} icon={<My.WorldMapIcon/>}>{t('World Map')}</My.WorldMap>
      }
      {
        auth.isAuthenticated && showValueChainMap && !map.isVisible &&
        <My.ExplorerMap isDisclosed={valueChainMap.isShown} onClick={() => { valueChainMap.toggle() }} icon={<My.Icon />}>{t(valueChainMap.isShown ? 'Hide Map' : 'Show Map')}</My.ExplorerMap>
      }
    </My.Container>
  )
};

SidebarButtons.cls = cls;
SidebarButtons.defaultProps = SidebarButtonsDefaultProps;