import React from 'react';
import styled, { css } from 'styled-components';
import { classNames, coalesce, entityLabel, formatDateString } from '@shapeable/utils'; const cls = classNames('featured-entity-card');
import { Classable, HasChildren, ContentEntity, ImageEntity as ImageEntityType, Shapeable } from '@shapeable/types';
import { AspectRatio, breakpoints, theme } from '@shapeable/theme';
import { CellHeader, ContentNode, EntityImage, EntityLink, EntityTextValue, ImageEntity, PLATFORM_DATE_FORMAT, useComponentColor, useEntity, useLang, useMonthNames } from '@shapeable/ui';
import { first, get } from 'lodash';

// -------- Types -------->

export type FeaturedEntityCardProps = Classable & HasChildren & { 
  entity: ContentEntity;
  aspectRatio?: AspectRatio;
  color?: string;
  hoverColor?: string;
  label?: string;
};

export const FeaturedEntityCardDefaultProps: Omit<FeaturedEntityCardProps, 'entity'> = {
  aspectRatio: { 
    base: 16/11,
    landscape: 21/11,
    tablet: 16/10,
  }
};

// -------- Child Component Props -------->

type ContainerProps = {
  _color: string;
  _hoverColor: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(2)};
    align-items: flex-start;
    
    padding-top: ${theme.UNIT(6)};
    border-top: 1px solid ${theme.COLOR('line-mid')};

    ${({ _color, _hoverColor }: ContainerProps ) => css`
      color: ${_color};

      &:hover {
        color: ${_hoverColor};
      }
    `}
  `,
  tablet: css`
    flex-direction: row;
  
  
  `,
 
});

const ImageStyles = breakpoints({
  base: css`
    
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(1)};
    color: ${theme.COLOR('text-mid')};
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-size: 0.9em;
    margin-bottom: ${theme.UNIT(1)};
    font-weight: 400;
    line-height: 1.1;
  `,
  desktopLarge: css`
    font-size: 1em;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    padding-bottom ${theme.UNIT(6)};
  `,
  tablet: css`
    padding-bottom ${theme.UNIT(4)};
    padding-right: ${theme.UNIT(4)};
    width: 150px;
  `,
  desktopLarge: css`
    width: 230px;
  `,
});

const LinkedStyles = breakpoints({
  base: css`
    font-weight: 300;
    color: ${theme.COLOR('text')};
    font-size: 0.7em;
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    font-weight: 300;
    font-size: 0.7em;

    .shp--citation-reference {
      top: 2px;
    }

    .shp--citation-reference__text {
      width: 12px;
      height: 12px;
    }
  `,
  desktopLarge: css`
    font-size: 0.8em;
  `,
});

const ImageContainerStyles = breakpoints({
  base: css`
    width: 190px;
    height: auto;
    flex-shrink: 0;
  `,
  desktopLarge: css`
    width: 190px;
  `,
});

const ImageContentStyles = breakpoints({
  base: css`
    display: flex;
    gap: ${theme.UNIT(4)};

  `,
});



// -------- Components -------->

const My = {
  Container: styled(EntityLink)<ContainerProps>`${ContainerStyles}`,
    ImageContent: styled.div`${ImageContentStyles}`,
    ImageContainer: styled.div`${ImageContainerStyles}`,
    Image: styled(ImageEntity)`${ImageStyles}`,

    Body: styled.div.attrs(cls.attr('body'))`${BodyStyles}`,
      Header: styled(CellHeader)`${HeaderStyles}`,
      Title: styled.h2.attrs(cls.attr('title'))`${TitleStyles}`,
      Linked: styled.span`${LinkedStyles}`,

    Description: styled(ContentNode)`${DescriptionStyles}`,
};

export const FeaturedEntityCard: Shapeable.FC<FeaturedEntityCardProps> = (props) => {
  const { className, children, aspectRatio } = props;
  const entity = useEntity(props.entity);
  
  const { name, banner, imageAssets = [], title, description, content, published, openGraph } = entity;
  let image: ImageEntityType = coalesce(entity, ['banner', 'logo', 'photo']);

  const t = useLang();
  const monthNames = useMonthNames();

  if (!(image && image.image && image.image.url)) {
    image = entity.openGraph;
  }

  if (!image && get(entity, 'thumbnail')) {
    image = {
      id: `entity-image-${entity.id}`,
      image:  get(entity, 'thumbnail'),
    }
  }

  const label = t(props.label || entityLabel(entity));

  const hoverColor = useComponentColor({
    cls,
    prop: props.color,
    fallbacks: ['link-hover', 'mid-brand', 'primary'],
    invertedFallbacks: ['light-brand', 'light'],
  });

  return (
    <My.Container _color={'#000'} _hoverColor={hoverColor} entity={entity} className={cls.name(className)}>
      <My.ImageContent>
        {
          image && 
          <My.ImageContainer>
            <My.Image aspectRatio={aspectRatio} entity={image} />
          </My.ImageContainer>
        }
        
        <My.Body>
        <My.Header>{t('Case Study')}</My.Header>
        <My.Title>{title || name}</My.Title>
        {
          published && 
          <My.Linked>{formatDateString(published, t('Do MMM YYYY'), PLATFORM_DATE_FORMAT, { monthNames })}</My.Linked>
        }
        {children}
        </My.Body>
        </My.ImageContent>


      <My.Description entity={content}/>

    </My.Container>
  )
};

FeaturedEntityCard.defaultProps = FeaturedEntityCardDefaultProps;
FeaturedEntityCard.cls = cls;