import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, OutlineEntity, Shapeable, Trend } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity } from '@shapeable/ui';
import { EntityCard, EntityCardProps } from './entity-card';
import { classNames } from '@shapeable/utils';
const cls = classNames('value-chain-card');

// -------- Types -------->

export type ValueChainCardProps = Classable & HasChildren & Omit<EntityCardProps, 'entity'> & { 
  entity?: OutlineEntity;
};

export const ValueChainCardDefaultProps: Omit<ValueChainCardProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type OutlineNumberProps = {

}

// -------- Styles -------->

const CardStyles = breakpoints({
  base: css`
    .shp--cell-label {
      color: ${theme.COLOR('light')};
    };
  `,
});

const NumberContainerStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(6)};
    margin-bottom: ${theme.UNIT(3)};
  `,
});

const OutlineNumberStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('light')};
    line-height: 1;
    font-size: ${theme.FONT_SIZE(38)};
    font-weight: 300;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('light')};
    line-height: 1.5;
    font-size: 20px;
    font-weight: 500;
  `,
});



// -------- Components -------->

const My = {
  Card: styled(EntityCard)<ContainerProps>`${CardStyles}`,
    NumberContainer: styled.div.attrs(cls.attr('number-and-name'))`${NumberContainerStyles}`,
      OutlineNumber: styled.span.attrs(cls.attr('outline-number'))<OutlineNumberProps>`${OutlineNumberStyles}`,
    
    Title: styled.span`${TitleStyles}`
};

export const ValueChainCard: Shapeable.FC<ValueChainCardProps> = (props) => {
  const { className, children, ...rest } = props;
  const entity = useEntity(props.entity);

  const { outlineNumber, name, color } = entity;
  
  return (
   <My.Card 
   className={cls.name(className)}
   {...rest}
   target="_blank"
   hasTitle={false}
   hasDescription={false}
   color={color?.value}
   >
    {
      outlineNumber && 
      <My.NumberContainer>
        <My.OutlineNumber >{outlineNumber}</My.OutlineNumber>
      </My.NumberContainer>
    }

    {
      name && 
      <My.Title>{name}</My.Title>
    }
   {children}
   </My.Card>
  )
};

ValueChainCard.defaultProps = ValueChainCardDefaultProps;
ValueChainCard.cls = cls;