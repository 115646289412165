import { ContentEntity, ContentNode, Dictionary, EntityTypeName, Page } from "@shapeable/types";
import { Deposit } from '@shapeable/copper-connect-types';
import { WithAuthenticationRequiredOptions } from '@auth0/auth0-react';

export const METALLOGENICA_PORTAL_DEPOSIT_URL="https://dmgeode.com/DepositPortal.aspx?DepositID=";

export const PAGE_EXPLORER: Page = {
  id: 'explorer',
  slug: 'explorer',
  path: '/explorer',
};

export const WITH_AUTHENTICATION_REQUIRED_OPTIONS: WithAuthenticationRequiredOptions = {
  loginOptions: {
    appState: { targetUrl: '/explorer' },
  }
};

export const CopperAiDescription: ContentNode = {
  text: "This innovative tool is designed to facilitate collaboration among industry leaders, policymakers, and researchers in pursuit of sustainable mining practices."
}

export const ENTITY_OPEN_GRAPH_FIELDS = `openGraph { id title description { id text } image { id url } }`;
export const ENTITY_SCHEMA_FIELDS = `_entityTypeName _schema { label pluralLabel }`;

export const GptBannerIntro: ContentEntity = { 
  intro: {
    text: "Our AI assistant specifically trained on Copper Connect's value chain themes. It has the capability to navigate the entire Copper Connect website on your behalf. It can explain valuable relationships and connections between important topics covered on the site and surface the most relevant related documents and articles you’d need. **Try it now!**" 
  }
};

export const DEFAULT_MAP_ENTITY_TYPE_NAMES: EntityTypeName[] = [
  'Organisation',
]