import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutStandard } from '@shapeable/web';
// import { useExplorer } from '../../hooks/use-explorer';

// -------- Styles -------->

const LayoutStyles = breakpoints({
  base: css`
    /* override title styles via BEM here to affect spacing */ 
    .shp--page-layout-standard__title {
      font-size: 2em;
      margin-bottom: ${theme.UNIT(8)};
      line-height: 1em;
      max-width: 300px;
      font-weight: 300;
      font-family: ${theme.FONT('sans')};
      strong, b {
        font-weight: 500;
      }
    }
  `,
  tablet: css`
    .shp--page-layout-standard__title {
      font-size: 2.75em;
      margin-bottom: ${theme.UNIT(16)};
    }
  `,
  desktop: css`
    .shp--page-layout-standard__title {
      font-size: 3.5em;
      margin-bottom: ${theme.UNIT(30)};
    }
  `
});

// -------- Components -------->

const My = {
  Layout: styled(PageLayoutStandard)`${LayoutStyles}`,
};

export const PageLayoutHome: PageLayoutComponent = (props) => {
  const { className, entity, slices } = props;

  return (
    <My.Layout 
      entity={entity}
      slices={slices}
      headerProps={{ variant: 'overlay' }}
      className={className}
    />
  )
};
