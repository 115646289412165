import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Entity, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity } from '@shapeable/ui';
import { ExplorerProvider } from '../providers/explorer-provider';
import { useValueChainMap } from '../../hooks/use-value-chain-map';
import { ExplorerAsideLayout } from './explorer-aside-layout';
import { ExplorerMainLayout } from './explorer-main-layout';
import { classNames } from '@shapeable/utils';
import { ExplorerLayout } from './explorer-layout';
const cls = classNames('explorer-layout');

// -------- Types -------->

export type ExplorerHomeLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerHomeLayoutDefaultProps: Omit<ExplorerHomeLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: ${theme.COLOR('light')};
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    flex-grow: 1;

    .shp--entity-main-header {
      display: none;
    }
  `,
  desktop: css`
    
  `,
});
const AsideStyles = breakpoints({
  base: css`
    
  `,
});

const MainStyles = breakpoints({
  base: css`
    padding: 0 ${theme.UNIT(5)} 0 ${theme.UNIT(5)};
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Layout: styled(ExplorerLayout).attrs(cls.attr('Layout'))`${LayoutStyles}`,
      Aside: styled(ExplorerAsideLayout)`${AsideStyles}`,
      Main: styled(ExplorerMainLayout)`${MainStyles}`
};

export const ExplorerHomeLayout: Shapeable.FC<ExplorerHomeLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const { isShown } = useValueChainMap();

  return (
    <ExplorerProvider>
    <My.Container className={cls.name(className)}>
      <My.Layout
        asideHeaderProps={{ showIcon: false }}
        entity={entity}
        asideIsRevealed={isShown}
        aside={
          <My.Aside entity={entity} />
        }
        main={
          <My.Main entity={entity} />
        }
      />
      {children}
    </My.Container>
    </ExplorerProvider>
  )
};

ExplorerHomeLayout.defaultProps = ExplorerHomeLayoutDefaultProps;
ExplorerHomeLayout.cls = cls;