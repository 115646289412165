import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityFilters, OrganisationMarketMapGrid, SearchBar, useOrganisations, useSearch, useView } from '@shapeable/ui';
import { compact } from 'lodash';
import { linkedMatchesFilter } from '../../utils/linked-matches-filter';
import { useMarketMapFilters } from '../../hooks/use-market-map-filters';
import { Organisation } from '@shapeable/copper-connect-types';
import { classNames } from '@shapeable/utils';

const cls = classNames('explorer-market-map-layout');

// -------- Types -------->

export type ExplorerMarketMapLayoutProps = Classable & HasChildren & {
}

export const ExplorerMarketMapLayoutDefaultProps: ExplorerMarketMapLayoutProps = {

};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const FiltersStyles = breakpoints({
  base: css`
    box-sizing: border-box;
    padding: ${theme.UNIT(4)} ${theme.UNIT(4)} 0;
  `,
});

const SearchBarStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(2)} ${theme.UNIT(4)} 0;
  `,
  tablet: css`
    padding: ${theme.UNIT(3)} ${theme.UNIT(4)} 0;
  `,
});

const GridStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
    padding: 0 ${theme.UNIT(4)} ${theme.UNIT(4)};
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
    SearchBar: styled(SearchBar)`${SearchBarStyles}`,
    Grid: styled(OrganisationMarketMapGrid)`${GridStyles}`,
};

export const ExplorerMarketMapLayout: Shapeable.FC<ExplorerMarketMapLayoutProps> = (props) => {
  const { className } = props;

  const allOrganisations = useOrganisations() as Organisation[];
  const marketMapFilters = useMarketMapFilters('explorer-community');
  const { selects } = marketMapFilters;

  const view = 'explorer';
  const { filters } = useView(view);
  
  const { countries = [], type = [], technologies = [], } = filters;

  const organisations = allOrganisations.filter(organisation => (
    linkedMatchesFilter(organisation.countries, countries) && 
    linkedMatchesFilter(compact([organisation?.type]), type) &&
    linkedMatchesFilter(compact(organisation?.technologies), technologies)
  ));

  const { searchTerm, setSearchTerm, filteredItems } = useSearch(organisations);

  const onChange = (name: string) => { 
    setSearchTerm(name);
   };

  return (
    <My.Container className={cls.name(className)}>
      <My.Filters 
        view={view}
        results={allOrganisations}
        selects={selects}
      />
      <My.SearchBar variant='allBorders' searchTerm={searchTerm} onChange={onChange} placeholder={`Search Stakeholders`}/>
      <My.Grid items={filteredItems} />
    </My.Container>
  )
};

ExplorerMarketMapLayout.cls = cls;
ExplorerMarketMapLayout.defaultProps = ExplorerMarketMapLayoutDefaultProps;