import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutStandard, SiteFooterLayout } from '@shapeable/web';

// -------- Styles -------->

const LayoutStyles = breakpoints({
  base: css`
    /* override title styles via BEM here to affect spacing */
    .shp--page-layout-standard__title {
      font-size: 2em;
      margin-bottom: ${theme.UNIT(8)};
      line-height: 1em;
      font-weight: 300;
      max-width: 500px;
      strong, b {
        font-weight: 500;
      }
    }
  `,
  tablet: css`
    .shp--page-layout-standard__title {
      font-size: 2.75em;
    }
  `,
  desktop: css`
    .shp--page-layout-standard__title {
      font-size: 3.5em;
    }
  `
});


const FooterStyles = breakpoints({
  base: css`
  `
});


// -------- Components -------->

const My = {
  Layout: styled(PageLayoutStandard)`${LayoutStyles}`,
    Footer: styled(SiteFooterLayout)`${FooterStyles}`,
};

export const PageLayoutDefault: PageLayoutComponent = (props) => {
  const { className, entity, slices } = props;

  return (
    <>
    <My.Layout 
      entity={entity}
      slices={slices}
      headerProps={{ variant: 'overlay' }}
      useIntro
      className={className}
    />
    <My.Footer backgroundColor='#FFFFFF' />
    </>
  )
};
