import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Page, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityCardGrid, WaitMessage, useView, EntityFilters, SearchBar, useSearch } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
import { useQuery, gql } from '@apollo/client';
import { ExplorerView } from './explorer-grid-layout';
const cls = classNames('explorer-impact-strategies');

// -------- Types -------->

export type ExplorerImpactStrategiesProps = Classable & HasChildren & {
}

export const ExplorerImpactStrategiesDefaultProps: ExplorerImpactStrategiesProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    .shp--card__body {
      background: var(--shapeable-white, #FFF);
    };

    padding: ${theme.UNIT(4)};
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    box-sizing: border-box;
    padding-bottom: ${theme.UNIT(2)};
  `,
  tablet: css`
    padding-bottom: ${theme.UNIT(3)};
  `,
});

const SearchBarStyles = breakpoints({
  base: css`
    
  `,
});

const GridStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
    SearchBar: styled(SearchBar)`${SearchBarStyles}`,
    Grid: styled(EntityCardGrid)`${GridStyles}`,
};

export const ExplorerImpactStrategies: Shapeable.FC<ExplorerImpactStrategiesProps> = (props) => {
  const { className } = props;
  const { resultsFor } = useView<ExplorerView>('explorer', 'impact-strategies');
  const { loading, data } = useQuery(
    gql`
      query {
        listPages(filter: { types: { in: ["impact-strategy"]} }) {
          results {
            name path slug __typename _schema { label pluralLabel }
            openGraph { id image { id url } }
          }
        }
        allCatalysts {
          id name path slug __typename _schema { label pluralLabel }
          openGraph { id image { id url } }
        }
      }
  `);

  const pages = data?.listPages?.results || [];
  const catalystsData = data?.allCatalysts || [];

  // patch the schema label, since these aren't true "ImpactStrategy" entities, 
  // they're pages bound to a sub-type

  const pagesWithName = pages.map((page: Page) => {
    return {
      ...page,
      _schema: {
        label: 'Impact Strategy',
      }
    }
  })

  const items = [
    ...(resultsFor({ type: 'Catalyst', data: catalystsData, by: 'name' })), 
    ...(resultsFor({ type: 'ImpactStrategy', data: pagesWithName, by: 'name' })), 
  ];

  const { searchTerm, setSearchTerm, filteredItems } = useSearch(items);

  const onChange = (name: string) => { 
    setSearchTerm(name);
   };

  return (
    <My.Container className={cls.name(className)}>
      <My.Filters 
        view="explorer"
        typeToggles={[
          { name: 'Catalyst' },
          { name: "ImpactStrategy", label: "Impact Strategies" },
        ]}
      />
      <My.SearchBar variant='allBorders' searchTerm={searchTerm} onChange={onChange} placeholder={`Search Impact Strategies`}/>
      {
        loading ? 
        <WaitMessage /> : 
        
        <My.Grid items={filteredItems} spacing={2} desktopAutoColumns={4} />
      }
    </My.Container>
  )
};

ExplorerImpactStrategies.cls = cls;
ExplorerImpactStrategies.defaultProps = ExplorerImpactStrategiesDefaultProps;