import * as React from 'react'; // tslint:disable-line
import styled, { css } from 'styled-components';
import { classNames, coalesce, entityLabel } from '@shapeable/utils'; 
import { Classable, HasChildren, Dictionary, Entity, EntityTypeName, ImageEntity as ImageEntityType, Shapeable } from '@shapeable/types';
import { get, includes } from 'lodash';
import { AspectRatio, breakpoints, theme } from '@shapeable/theme'
import { Card, CardProps, CellHeader, ContentNode, ImageEntity, ResponsiveContainer, useComponentPropsFor, useLang, useLink } from '@shapeable/ui';


const cls = classNames('entity-card');

// -------- Types -------->

export type EntityCardProps = Classable & HasChildren & CardProps & {
  entity?: Entity;
  label?: string;
  linkEntireCard?: boolean;
  href?: string;
  target?: string;
  hspace?: boolean;
  footer?: React.ReactNode;
  imageAspectRatio?: AspectRatio;
  maxWords?: number;
  moreLabel?: string;
  labelFieldPath?: string;
  hasLabel?: boolean;
  hasTitle?: boolean;
  hasDescription?: boolean;
  color?: string;
}

export const EntityCardDefaultProps: EntityCardProps = {
  imageAspectRatio: { 
    base: 16 / 9, 
    landscape: 21 / 9, 
    tablet: 16 / 9,
  },
  spaceUnits: 4,
  linkEntireCard: false,
  moreLabel: 'read more',
  linkVariant: 'title-image',
  hasLabel: true,
  hasTitle: true,
  hasDescription: true,
  color: '#EBEBEB',
};

type CardPropTypes = {
  _color: string;
}

type TitleProps = {
  hasLink: boolean;
};

type ImageProps = {
  hasLink?: boolean;
};

// -------- Styles -------->

const CardStyles = breakpoints({
  base: css`
    display: flex;
    margin-bottom: ${theme.UNIT(4)};
    border-radius: 0 0 ${theme.UNIT(3)} ${theme.UNIT(3)};
    ${({ _color }: CardPropTypes ) => css`
      background-color: ${theme.COLOR(_color)};
      border-top: 3px solid ${theme.COLOR(_color)};
    `}
    
  `,
});

const ContentStyles = breakpoints({
  base: css`
    flex-grow: 1;
    font-weight: inherit;
    padding-top: ${theme.UNIT(3)};
    padding-bottom: ${theme.UNIT(3)};
    margin-top: ${theme.UNIT(3)};
    ${theme.H_DOT_BG()};
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    font-size: ${theme.FONT_SIZE(18)};
    font-weight: 500;
    line-height: 1.25em;
    margin-bottom: 0;
    color: ${theme.COLOR('dark')};
    background: none;
    margin-top: 0;
    margin-bottom: ${theme.UNIT(2)};

    ${({ hasLink }: TitleProps ) => hasLink && css`
      &:hover {
        color: ${theme.COLOR('link-hover')};
      }
    `}
  `,
});

const ImageStyles = breakpoints({
  base: css`
    
    ${({ hasLink }: TitleProps ) => hasLink && css`
      transition: filter 0.2s;

      &:hover {
        filter: brightness(1.1);
      }
    `}

    img {
      object-fit: cover;
    }
  `,
});

const ImageContainerStyles = breakpoints({
  base: css`
    
  `,
});


const HeaderStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(1)};
  `,
});

const FooterStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG()};
  `,
});


// -------- Components -------->

const My = {
  Card: styled(Card)<CardPropTypes>`${CardStyles}`,

  ImageContainer: styled(ResponsiveContainer)`${ImageContainerStyles}`,
    Image: styled(ImageEntity)<ImageProps>`${ImageStyles}`,

  Content: styled(ContentNode)`${ContentStyles}`,
    Title: styled.span.attrs(cls.attr('title'))<TitleProps>`${TitleStyles}`,
  
  Header: styled(CellHeader)`${HeaderStyles}`,
  Footer: styled.div.attrs(cls.attr('footer'))`${FooterStyles}`,
};

/**
 * Presents an entity in a 'card' format, which is an image stacked above text and details. This is generally used inside a grid component on listings pages.
 */

export const EntityCard: Shapeable.FC<EntityCardProps> = (props) => {
  const { className, entity, target, imageAspectRatio, maxWords, linkEntireCard, spaceUnits, moreLabel, linkVariant, hasLabel, hasTitle, hasDescription, color } = 
    useComponentPropsFor<EntityCardProps>('EntityCard', EntityCardDefaultProps, props);

  const description = coalesce(entity, ['description', 'summary', 'intro', 'content', 'openGraph.description']);
  let image: ImageEntityType = coalesce(entity, ['banner', 'logo', 'photo']);

  const t = useLang();

  const labelAtPath = props.labelFieldPath && get(entity, props.labelFieldPath);
  const label = t(labelAtPath || props.label || entityLabel(entity));
  
  const { Link } = useLink();

  const href = props.href === undefined ? entity.path : props.href;

  const hasTitleLink = !!href && includes(['title', 'title-image'], linkVariant);

  if (!image && entity.openGraph && entity.openGraph.image) {
    image = {
      id: `entity-image-${entity.id}`,
      image: entity.openGraph.image,
    }
  }

  if (!image && get(entity, 'image')) {
    image = {
      id: `entity-image-${entity.id}`,
      image:  get(entity, 'image'),
    }
  }

  if (!image && get(entity, 'thumbnail')) {
    image = {
      id: `entity-image-${entity.id}`,
      image:  get(entity, 'thumbnail'),
    }
  }

  const titleElement = <My.Title hasLink={hasTitleLink}>{entity.name}</My.Title>;

  const imageLinkStyle = {
    height: '100%',
    width: '100%',
    display: 'block',
  };

  return (
    <My.Card
      {...props}
      href={linkEntireCard && href}
      target={target}
      spaceUnits={spaceUnits}
      className={cls.name(className)}
      imageAspectRatio={!props.image && imageAspectRatio}
      image={props.image || (image && ((linkVariant === 'title-image' && !!href) ? <Link style={imageLinkStyle} href={href}><My.Image hasLink entity={image} /></Link> : <My.Image entity={image} />))}
      _color={color}
    >
      {
        hasLabel &&
        <My.Header>{label}</My.Header>
      }
      {
        hasTitle &&
        (
          hasTitleLink ? 
          <Link style={{ textDecoration: 'none' }} href={href}>{titleElement}</Link> :  titleElement
        )
      }
      {props.children}
      {
        description && hasDescription && 
        <My.Content moreLabel={moreLabel} maxWords={maxWords} entity={description} />
      }
    </My.Card>
  );
};

// EntityCard.defaultProps = EntityCardDefaultProps;
EntityCard.cls = cls;

