import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { TabsContainer, useActiveEntity, useLang, useLink } from '@shapeable/ui';
import { TabVariant } from './tab';
import { compact } from 'lodash';
import { Tab } from './tab';
import { ContentTab } from './content-tab';
import { classNames } from '@shapeable/utils';
const cls = classNames('tabs');


// -------- Types -------->

export type TabSpec<IdType extends string = string> = {
  id: IdType;
  label?: string;
  isLoading?: boolean;
  count?: number;
  icon?: React.ReactNode;
}

// -------- Props -------->

export type TabsProps = Classable & HasChildren & {
  tabs: TabSpec[];
  onTabClick?: (tab: string) => void;
  activeTab?: string;
  variant?: TabVariant;
}

export const TabsDefaultProps: TabsProps = {
  activeTab: 'all',
  tabs: [],
};

// -------- Child Component Props -------->

type ContainerProps = {
  _isSticky?: boolean;
}

type TabProps = {
  _isSticky?: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: row;

    ${({ _isSticky }: ContainerProps) => _isSticky && css`
      position: fixed;
      top: 64px;
      left: 0;
      right: 0;
      z-index: 90;
      width: calc(100% - ${theme.UNIT(14)});
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      /* animation-delay: 0.8s;
      animation: slideDown 0.4s forwards;

      @keyframes slideDown {
        from { transform: translateY(-100%); }
        to { transform: translateY(0); }
      } */

    `}
  `,
  desktop: css`
    padding: 0 ${theme.UNIT(6)};
    box-sizing: border-box;
  `,
});

const TabStyles = breakpoints({
  base: css`

  `,
});



// -------- Components -------->

const My = {
  Container: styled(TabsContainer)<ContainerProps>`${ContainerStyles}`,
    Tab: styled(Tab)<TabProps>`${TabStyles}`,
};

export const Tabs: Shapeable.FC<TabsProps> = (props) => {
  const { className, onTabClick, tabs, activeTab, variant } = props;

  const [isSticky, setSticky] = useState<boolean>(false);

    useEffect(() => {
      const handleScroll = () => {
        const tabsOffsetTop = document.getElementById(cls.name(className))?.offsetTop || 0;
        setSticky(window.scrollY > tabsOffsetTop + 110);
    };

      handleScroll();

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  const t = useLang();

  const handleClick = (tab: TabSpec) => () => {
    if (onTabClick) {
      onTabClick(tab.id);
    }
  };

  return (
    <My.Container showFade={false} _isSticky={isSticky} className={cls.name(className)}>
      {
        compact(tabs).map((tab, index) => {
          const nextTab = index < tabs.length - 1 ? tabs[index + 1] : null;
          const nextIsActive = nextTab && nextTab.id === activeTab;
          const isLast = index === tabs.length - 1;
          const isFirst = index === 0;

          const hasLabelAndIcon = !!tab.label && !!tab.icon;

          return (
            <My.Tab 
              isLoading={tab.isLoading} 
              onClick={handleClick(tab)} 
              key={tab.id} count={tab.count} 
              nextIsActive={nextIsActive} 
              variant={variant} 
              isLast={isLast} 
              isFirst={isFirst} 
              isActive={activeTab === tab.id}
              hasIconLabel={hasLabelAndIcon}
              icon={tab.icon}
              label={tab.label}
              isSticky={isSticky}
              _isSticky={isSticky}
              >
              {!hasLabelAndIcon && (tab.label || tab.icon)}
              </My.Tab>  
          );

        })
      }
    </My.Container>
  )
};

Tabs.cls = cls;
Tabs.defaultProps = TabsDefaultProps;