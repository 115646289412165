import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, IconComponentProps, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
const cls = classNames('trend-bubble-dot');

import { darken } from 'polished';

// -------- Types -------->

export type TrendBubbleDotProps = Classable & HasChildren & {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  isActive?: boolean;
  color?: string;
}

export const TrendBubbleDotDefaultProps: TrendBubbleDotProps = {
  color: '#333333',
};

// -------- Child Component Props -------->

type ContainerProps = {
  _color: string;

}

type CircleProps = {
}


// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    overflow: visible;
    ${({ _color }: ContainerProps ) => css`
      transition: all 0.3s;
      ${cls.dot('dot')} {
        fill: ${_color}
      }
    `}

  `,
});

const CircleStyles = breakpoints({
  base: css`
  `,
});


// -------- Components -------->

const My = {
  Container: styled.svg<ContainerProps>`${ContainerStyles}`,
  Circle: styled.circle`${CircleStyles}`,
};

export const TrendBubbleDot: Shapeable.FC<TrendBubbleDotProps> = (props) => {
  const { className, color, x, y, width, height, isActive } = props;
  return (
    <My.Container  _color={color} x={x} y={y} width={width} height={height} className={cls.name(className)} viewBox="0 0 102 102">
    <g className={cls.sub('bubble')}>
    <My.Circle cx="51" cy="51" r="51" className={cls.sub('line')}></My.Circle>
      <path d="M51.0028 101.579C79.0532 101.579 101.793 78.8401 101.793 50.7897C101.793 22.7393 79.0532 0 51.0028 0C22.9524 0 0.213135 22.7393 0.213135 50.7897C0.213135 78.8401 22.9524 101.579 51.0028 101.579Z" fill={color} className={cls.sub('line')} />
        <g opacity="0.25">
          <path d="M50.7897 90.282C29.0088 90.282 11.2974 72.5705 11.2974 50.7897C11.2974 29.0088 29.0088 11.278 50.7897 11.278C61.6801 11.278 71.5629 15.7155 78.7134 22.866L86.6971 14.8823C77.4732 5.69713 64.7806 0 50.7897 0C22.7885 0 0 22.7885 0 50.7897C0 78.7909 22.7885 101.579 50.7897 101.579C64.8 101.579 77.4925 95.8823 86.6777 86.6971L78.694 78.7134C71.5435 85.8638 61.6607 90.3014 50.7703 90.3014L50.7897 90.282Z" fill="white" />
        </g>
      </g>
    </My.Container>
  )
};

TrendBubbleDot.cls = cls;
TrendBubbleDot.defaultProps = TrendBubbleDotDefaultProps;