import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, CitationsProvider as Provider } from '@shapeable/ui';
import { sortBy } from 'lodash';

const CITATIONS_QUERY = graphql`
  query PlatformCitationsQuery {
    platform {
      allCitations {
        __typename _schema { label pluralLabel }
        id path slug url name
        authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
      }
    }
  }`;

export const CitationsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(CITATIONS_QUERY), 'allCitations' ), 'name') }>{children}</Provider>;
};
