import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { useCommodityGroups } from '../../hooks/use-commodity-groups';
import { EntityThumbnailCardGrid } from './entity-thumbnail-card-grid';
const cls = classNames('explorer-commodity-groups-layout');

// -------- Types -------->

export type ExplorerCommodityGroupsLayoutProps = Classable & HasChildren & {
}

export const ExplorerCommodityGroupsLayoutDefaultProps: ExplorerCommodityGroupsLayoutProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const GridStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Grid: styled(EntityThumbnailCardGrid)`${GridStyles}`,
};

export const ExplorerCommodityGroupsLayout: Shapeable.FC<ExplorerCommodityGroupsLayoutProps> = (props) => {
  const { className } = props;

  const commodityGroups = useCommodityGroups();

  //filtered for now. Having Site assigned did not work.
  const filteredCommoditiyGroups  = commodityGroups.filter(commodity => commodity?.name.toLocaleLowerCase() === 'copper');

  return (
    <My.Container className={cls.name(className)}>
      <My.Grid items={filteredCommoditiyGroups} />
    </My.Container>
  )
};

ExplorerCommodityGroupsLayout.cls = cls;
ExplorerCommodityGroupsLayout.defaultProps = ExplorerCommodityGroupsLayoutDefaultProps;