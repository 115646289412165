import { ThemeOverrides, breakpoints, theme } from "@shapeable/theme";
import { Color } from "@shapeable/types";
import { css } from "styled-components";


export const ORANGE = '#DE7700';

export const DARK_BLUE = '#082F40';
export const AQUA = '#009DA5';
export const COPPER = '#A66100';
export const ICAA = '#4A9A7F';

export const CORE_1 = '#A1A04A';
export const CORE_2 = '#C6B36A';
export const CORE_3 = '#E7BC44';
export const CORE_4 = '#CD7D4B';
export const CORE_5 = '#C27768';
export const CORE_6 = '#CEB0A7';
export const CORE_7 = '#C1C0C8';
export const CORE_8 = '#A9B2BE';
export const CORE_9 = '#698EB2';
export const CORE_10 = '#337589';
export const CORE_11 = '#3B6772';
export const CORE_12 = '#293D4A';
export const CORE_13 = '#2F353C';
export const CORE_14 = '#304042';
export const CORE_15 = '#416161';
export const CORE_16 = '#527C6A';
export const CORE_17 = '#6D8C6B';

export const COLOR_VALUE_MINE_DEVELOPMENT = '#3B6673';
export const COLOR_VALUE_MATERIAL_MOVEMENT = '#E5BB44';
export const COLOR_VALUE_DISCOVERY = '#6B8B6A';
export const COLOR_VALUE_ENERGY = '#CC7C4B';
export const COLOR_VALUE_AIR = '#A9B0BD';
export const COLOR_VALUE_WATER = '#698CB0';
export const COLOR_VALUE_CIRCULAR_ECONOMY = '#406161';
export const COLOR_VALUE_MINERAL_PROCESSING = '#C17768';


export const BRAND_COLORS: string[] = [
  COLOR_VALUE_DISCOVERY, COLOR_VALUE_MINE_DEVELOPMENT, COLOR_VALUE_MATERIAL_MOVEMENT, 
  COLOR_VALUE_MINERAL_PROCESSING, COLOR_VALUE_CIRCULAR_ECONOMY, COLOR_VALUE_WATER, 
  COLOR_VALUE_AIR, COLOR_VALUE_ENERGY,
];


export const MEDIUM_BLUE = '#2496BD';
export const CONTENT_BACKGROUND = '#FFF';


export const themeOverrides: ThemeOverrides = {
  light: {
    color: {
      primary: ICAA,
      link: ICAA,
      linkInvertedHover: ICAA,
      linkHover: ICAA,
      linkActive: ICAA,
      invertedLinkActive: ICAA,
      // invertedMenuHover: ICAA,

      invertedMenuActive: ICAA,
      invertedMenuHoverBackground: ICAA,

      midBrand: ICAA,
    },
  },
  font: {
    family: {
      serif: 'Montserrat',
      sans: 'Montserrat',
      heading: 'Montserrat',
      copy: 'Montserrat',
    }
  },
};



/* 
--------------------

  Layout Styles
  
  NB: This is the appropriate place to override styling for this specific site, based on BEM-style class name targets 
  
--------------------
*/

export const LayoutStyles = breakpoints({
  base: css`

    .shp--content {
      h1, h2, h, h4, h5, h6 {
        font-weight: 500;
        strong {
          color: inherit;
        }
      }
    } 

    .shp--

    .shp--organisation-bio-cell {
      img,
      .gatsby-image-wrapper img {
        border-radius: 0;
        padding: 30px;
        object-fit: contain !important;
      }
    }

    .shp--organisation-powered-by {
      opacity: 0.6;

      img {
        mix-blend-mode: overlay;
        filter: grayscale(1);
        max-width: 120px;
      }

      &:hover {
        filter: none;
      }
    }

    .shp--page-renderer {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }


    
    .shp--site-menu-bar__title {
      color: #FFF;
    }

    .shp--slice-intro .shp--markdown-content {
      font-size: 1.5em;
      text-align: left;
      line-height: 1.35em;
      font-weight: 300;
      max-width: 900px;
    }

    .shp--slice-layout {

      .shp--content-title {
        font-size: 24px;
      }

      .shp--content-entity-block__content {
        font-size: ${theme.FONT_SIZE(15)};
      }

      .shp--slice-section-color-block {
        max-height: 600px;
      }

      .shp--slice-content-image {
      }

      .shp--slice-contact-form,
      .shp--slice-latest-insights,
      .shp--slice-key-message {
      }

      .shp--slice-intro {
        font-weight: 400;
      }
    }

    .shp--slim-select__option {
      &:hover {
        background-color: #EBEBEB;
      }
    }

    .shp--slim-select__option--is-focused {
      background-color: #EBEBEB;
    }

    .shp--entity-select-option {
      &:hover {
        background-color: #EBEBEB;
      }
    }

    .shp--entity-select__option--is-focused {
        background-color: #EBEBEB;
    }

  `,
  tablet: css`
    .shp--slice-layout {

      .shp--content-title {
        font-size: 28px;
      }

      .shp--content-entity-block__content {
        font-size: ${theme.FONT_SIZE(16)};
      }

      .shp--slice-intro .shp--markdown-content {
        font-size: 2em;
      }
    }
  `,
}); 
