import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { InnovationsProvider as Provider } from '../../components/context/innovations-context';

const QUERY = graphql`
  query InnovationsQuery {
    platform { 
      allInnovations { 
        id name path slug __typename _entityTypeName
        horizons { id type { id slug value } }
        trends { id slug }
        technologies { id name slug }
        catalysts { id name slug }
        openGraph {
          title date description { plain }
          image { 
            url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } 
            thumbnails { full { url } }
          }
        }
      }
    }
  }
`

export const InnovationsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allInnovations' ) }>{children}</Provider>;
};