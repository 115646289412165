import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { PostsProvider as Provider, platformProviderValue } from '@shapeable/ui';

const POSTS_QUERY = graphql`
  query PostsProviders {
    platform {
      allPosts { 
        id name path slug __typename
        _schema { label pluralLabel }
        intro { text }
        content { text(truncate: { words: 30} ) }
        title
        openGraph { id image { thumbnails { full { id url } } } }
        type { id name slug __typename }
        published
        authors {
          openGraph { image { url } }
          _schema { label pluralLabel }
          id name slug path
          organisation { id name }
          linkedin
          photo { id url type static { childImageSharp { gatsbyImageData } } }
        }
      }
    }
  }`;

export const PostsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(POSTS_QUERY), 'allPosts' ) }>{children}</Provider>;
};