import { CellLabel, ContentNode, EntityTooltip, EntityTooltipProps, EntityValue, PlainLinkButton, useEntity, useLang, useLink } from '@shapeable/ui';
import React from 'react';
import styled, { css } from 'styled-components'; 
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Organisation } from '@shapeable/copper-connect-types';
import { ChevronRightIcon } from '@shapeable/icons';

const cls = classNames('organisation-tooltip');

// -------- Types -------->

export type OrganisationTooltipProps = EntityTooltipProps & { 
  entity?: Organisation;
  onLinkClick?: React.MouseEventHandler;
};

export const OrganisationTooltipDefaultProps: Omit<OrganisationTooltipProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type ValueProps = {
  hasLink: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size ${theme.FONT_SIZE(14)};
    text-align: left;
    padding: ${theme.UNIT(5)};
  `,
});

const BodyStyles = breakpoints({
  base: css`
   
 `,
});

const OrganisationLabelStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const ValueStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};

    .gatsby-image-wrapper img {
      object-fit: contain !important;
      box-sizing: border-box; !important;
      padding: 8px;
    };

    .shp--cell-label {
      color: ${theme.COLOR('text')};
    }

    ${({ hasLink }: ValueProps ) => hasLink && css`
      cursor: pointer;

      &:hover {
        color: ${theme.COLOR('link-hover')};
      }
    `}
  `,
});

const ContentStyles = breakpoints({
  base: css`
    flex-grow: 1;
    font-weight: inherit;
    padding-top: ${theme.UNIT(3)};
    padding-bottom: ${theme.UNIT(3)};
    margin-top: ${theme.UNIT(3)};
    font-family: ${theme.FONT('sans')};
    color: ${theme.COLOR('text')};
    ${theme.H_DOT_BG()};
  `,
});

const LinkButtonStyles = breakpoints({
  base: css`
    display: flex;
    gap: ${theme.UNIT(2)};
    font-weight: 400;
    font-size: ${theme.FONT_SIZE(14)};
    align-items: center;
    font-family: ${theme.FONT('sans')};
    color: ${theme.COLOR('dark')};
    &:hover {
      ${theme.FILL('link-hover')};
      color: ${theme.COLOR('link-hover')} !important;
    };
  `,
});

const FooterStyles = breakpoints({
  base: css`
  padding-top: ${theme.UNIT(4)};
  ${theme.H_DOT_BG()};  
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    OrganisationLabel: styled(CellLabel)`${OrganisationLabelStyles}`,
    Body: styled.div`${BodyStyles}`,
      Value: styled(EntityValue)<ValueProps>`${ValueStyles}`,
      Content: styled(ContentNode)`${ContentStyles}`,
      Footer: styled.div`${FooterStyles}`,
      LinkButton: styled(PlainLinkButton)`${LinkButtonStyles}`, 
};

export const OrganisationTooltip: React.FC<OrganisationTooltipProps> = (props) => {
  const { className, children, onLinkClick } = props;
  const entity = useEntity(props.entity);
  
  const { url, openGraph } = entity;

  const { description } = openGraph;

  const t = useLang();

  const { Link } = useLink();

  const hasLink = !!url;
  const hasDescription = !!openGraph?.description;

  return (
    <My.Container className={cls.name(className)}>
      <My.Value hasLink={false} imageStyle='logo' showImage={true} entity={entity} />
      {
        hasDescription && 
        <My.Content maxWords={50} entity={description} />
      }
      {
        <My.Footer>
          <Link style={{ textDecoration: 'none' }} href={url} >
            <My.LinkButton>
              <ChevronRightIcon size={20} />
              {t('View Organisation')}
            </My.LinkButton>
          </Link>
        </My.Footer>
      }

    </My.Container>
  )
};

OrganisationTooltip.defaultProps = OrganisationTooltipDefaultProps;