import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, OutlineEntity, Page, Shapeable, Trend } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity } from '@shapeable/ui';
import { EntityCard, EntityCardProps } from './entity-card';
import { classNames } from '@shapeable/utils';
const cls = classNames('page-card');

// -------- Types -------->

export type PageCardProps = Classable & HasChildren & Omit<EntityCardProps, 'entity'> & { 
  entity?: Page;
};

export const PageCardDefaultProps: Omit<PageCardProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type OutlineNumberProps = {

}

// -------- Styles -------->

const CardStyles = breakpoints({
  base: css`
    .shp--cell-label {
      color: ${theme.COLOR('text')};
      font-weight: 300;
    };
  `,
});

const TitleStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('dark')};
    line-height: 1.5;
    font-size: 16px;
    font-weight: 300;
  `,
});



// -------- Components -------->

const My = {
  Card: styled(EntityCard)<ContainerProps>`${CardStyles}`,
    Title: styled.span`${TitleStyles}`
};

export const PageCard: Shapeable.FC<PageCardProps> = (props) => {
  const { className, children, ...rest } = props;
  const entity = useEntity(props.entity);

  const { name, color, title } = entity;
  
  return (
   <My.Card 
   className={cls.name(className)}
   {...rest}
   target="_blank"
   hasTitle={false}
   label={name}
   hasDescription={false}
   >
    {
      name && 
      <My.Title>{title}</My.Title>
    }
   {children}
   </My.Card>
  )
};

PageCard.defaultProps = PageCardDefaultProps;
PageCard.cls = cls;